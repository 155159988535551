import Filters from 'app/lib/filters';
import Session from 'app/lib/session';
import AdminRoleModel from 'app/model/admin-role';
import UtilModel from 'app/model/util';
import { each, get, has, map, zipObject } from 'lodash-es';
import Moment from 'moment';
import Auth from 'app/lib/auth';

/**
 * @type {{prepareIdLinks(*=): [], toWordCase(*): *, formSetPristine(*=): *, match(String, Object, Object=): *, convertToVueSelectList(*=): {text: any, value: string}[], getNodeList(*, *, *=): Promise<*>, randomId(*=): *, syncLists(*=): *, formSetDirty(*=): *, syncAdminRoles(): Promise<*>, toTitleCase(*): *, toFirstUpper(*): *, getLists(): *, convertToVueSelectListReverse(*=): {text: any, value: string}[], prepareLinks(*=, *): Array}}
 */
const Util = {
  /**
   * On User login, all enums are requested from the API using UtilModel.getLists()
   *
   * @returns {*}
   */
  getLists() {
    return Session.get('utilLists') || [];
  },

  getEnums(namespace) {
    return Util.convertToVueSelectList(Util.getLists()[namespace]);
  },

  /**
   * Used in conjunction with CXI EnumLists and Vuetify <VSelect :items>
   * to convert [{KEY: VALUE}] to [{value: KEYNAME, text: VALUENAME}]
   *
   * @param list
   * @returns {{text: any, value: string}[]}
   */
  convertToVueSelectList(list = []) {
    return Object.entries(list).map(([value, text]) => ({ value, text }));
  },

  /**
   * @param enumLists
   * @param {Boolean} isLegacyKey  if true, key is Texas not TX (new standard is using ISO code)
   * @return {{text: string, value: *}[]}
   */
  getVueStateList(enumLists, isLegacyKey = false) {
    return Object.entries(enumLists.state).map(([code, fullState]) => ({ value: isLegacyKey ? fullState : code, text: `${code} - ${fullState}` }));
  },

  /**
   * NOTE: This is different from above because some lists are in reverse order (e.g. StateList for Email CRUD)
   * Used in conjunction with CXI EnumLists and Vuetify <VSelect :items>
   * to convert [{KEY: VALUE}] to [{value: KEYNAME, text: VALUENAME}]
   *
   * @param list
   * @returns {{text: any, value: string}[]}
   */
  convertToVueSelectListReverse(list) {
    return Object.entries(list).map(([text, value]) => ({ value, text }));
  },

  getOrdinal(str) {
    const n = Number(str);
    return n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th');
  },

  /**
   *
   * @returns {*}
   */
  async syncLists(isReset = false) {
    let lists = {};
    if (!isReset) {
      lists = Session.get('utilLists');
      if (typeof lists === 'object') return lists;
    }

    const dbLists = await UtilModel.getLists();
    dbLists.forEach((list) => {
      lists[list.name] = zipObject(
        list.selectList,
        map(list.selectList, (row) => Filters.titlecase(row)),
      );
    });

    lists.state = {
      AA: 'Nationwide',
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    };

    return Session.set('utilLists', lists);
  },

  async syncAdminRoles() {
    const adminRoles = Session.get('adminRoles');
    if (adminRoles) return adminRoles;

    const list = await AdminRoleModel.query({ limit: 100 });
    const sortedList = list.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
    return Session.set('adminRoles', sortedList);
  },

  async getNodeList(chapterId, model, isFocusMode = false) {
    let result = await model.query({
      criteria: { type: 'nodeList', chapterId, isFocusMode },
      limit: 1000,
    });
    result = result.nodeList;
    return result;
  },

  /**
   * @memberof Util
   * @param objForm
   * @returns {*}
   */
  formSetPristine(objForm) {
    if (has(objForm, '$pristine')) {
      objForm.$setPristine();
      Object.assign(objForm, {
        modifiedModels: [],
        modified: false,
        modifiedCount: 0,
      });
    }

    return objForm;
  },

  /**
   * @memberof Util
   * @param objForm
   * @returns {*}
   */
  formSetDirty(objForm) {
    if (has(objForm, '$pristine')) {
      objForm.$setDirty();
      Object.assign(objForm, {
        modified: true,
        modifiedCount: objForm.modifiedCount + 1,
      });
    }

    return objForm;
  },

  /**
   * Take a link list full object and map down to either a simple ID object list or ID array
   *
   * @memberof Util
   * @param list
   * @param isString If true, return String[], else Object[]._id
   * @return {Array}
   */
  prepareLinks(list, isString) {
    const srcList = [];
    each(list, (link) => {
      const row = isString ? link._id : { _id: link._id };
      srcList.push(row);
    });

    return srcList;
  },

  prepareIdLinks(list) {
    const srcList = [];
    each(list, (link) => {
      srcList.push(link._id);
    });

    return srcList;
  },

  randomId(digits = 10) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const uIntArray = new window.Uint8Array(digits);
    window.crypto.getRandomValues(uIntArray);
    const numArray = Array.from(uIntArray);
    return numArray.map((num) => chars[num % chars.length]).join('');
  },

  toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  },

  toFirstUpper(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  toWordCase(str) {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
  },

  /**
   * Switch match
   *
   * @param {String} check     The switch parameter to match against
   * @param {Object} list      An object of keys and functions or string responses to match against check
   * @param {Object} [params]  Additional parameters to assign if using functions
   * @returns {*}
   */
  match(check, list, params) {
    const match = list[check] || list._;
    if (typeof match === 'function') {
      return match({ check, ...params });
    }

    return match;
  },

  formatBloomList(bloomTypeList) {
    const blooms = [];
    Object.entries(bloomTypeList).forEach((x) => {
      blooms.push({ titleId: x[0], title: x[1] });
    });
    return blooms;
  },

  /**
   * Filters a value based on the provided filter name.
   *
   * @param {string} filter - The name of the filter to apply.
   * @param {any} val - The value to be filtered.
   */
  filter(filter, val) {
    if (val == null || val === false || val.length === 0) {
      return '';
    }

    if (filter && Filters[filter]) {
      return Filters[filter](val);
    }

    return val;
  },

  getValue(obj, key, filter, idx) {
    if (key === '#') {
      return idx + 1;
    }

    let val = '';
    if (typeof filter === 'function') {
      const keys = key.split('.');
      if (keys.length === 2) {
        val = filter(obj[keys[0]][keys[1]], obj);
      } else {
        val = filter(obj[key], obj);
      }
    } else {
      val = get(obj, key);
    }

    return this.filter(filter, val);
  },

  goto(id, page, state, parentSet, parentType) {
    return { name: `${state}.${page}`, params: { id }, query: { parent: parentSet, parenttype: parentType } };
  },

  copyIdLink(itemId, toast) {
    const x = document.createElement('input');

    document.body.appendChild(x);
    x.value = itemId;
    x.select();

    const copied = document.execCommand('copy');
    if (copied) {
      toast({ text: 'ID Copied' });
    }

    // this code was added to avoid that selection keeps active
    const selection = window.getSelection ? window.getSelection() : document.selection;
    if (selection && selection.removeAllRanges) {
      selection.removeAllRanges();
    } else if (selection && selection.empty) {
      selection.empty();
    }

    document.body.removeChild(x);
  },

  isDateFormatChange(oldDate, newDate) {
    const dateFormat = 'MM/DD/YYYY';
    const oldMoment = Moment(oldDate, dateFormat, true);
    const newMoment = Moment(newDate, dateFormat, true);
    // Check if both old and new dates are valid and if the day and month are swapped
    return oldMoment.isValid() && newMoment.isValid() && oldMoment.date() === newMoment.month() + 1 && oldMoment.month() === newMoment.date() - 1;
  },

  unlink(ev, id, instanceObj, text) {
    // Appending dialog to document.body to cover sidenav in docs app
    instanceObj.$dialog.confirm({
      title: `Unlink ${instanceObj.label}?`,
      text: `${text || `Are you sure you want to unlink?`}${!instanceObj.autoSave ? ' Remember, you will still have to save.' : ''}`,
      confirmText: 'Unlink',
      async callback() {
        while (instanceObj.list.findIndex((item) => item[instanceObj.answerabilityAttribute || '_id'] === id) > -1) {
          const itemPosition = instanceObj.list.findIndex((item) => item[instanceObj.answerabilityAttribute || '_id'] === id);
          if (instanceObj.hasTopics && instanceObj.list.filter((item) => item[instanceObj.answerabilityAttribute || '_id'] === id).length === 1) {
            // if is the unique item with that id and there are topics
            if (instanceObj.list[itemPosition - 1] && !instanceObj.list[itemPosition - 1]._id) {
              // if previous item is a topic
              // update the chapterId of the topic, pointing to the next node
              instanceObj.obj.topicList[instanceObj.list[itemPosition - 1].pos].chapterId = instanceObj.list[itemPosition + 1]?._id || instanceObj.list[itemPosition + 1]?.chapterId || undefined;
            }
          }
          instanceObj.list.splice(
            instanceObj.list.findIndex((item) => item[instanceObj.answerabilityAttribute || '_id'] === id),
            1,
          );
        }
        while (instanceObj.obj[instanceObj.childListKey].findIndex((item) => item[instanceObj.answerabilityAttribute || '_id'] === id || item === id) > -1) {
          instanceObj.obj[instanceObj.childListKey].splice(
            instanceObj.obj[instanceObj.childListKey].findIndex((item) => item[instanceObj.answerabilityAttribute || '_id'] === id || item === id),
            1,
          );
        }
        if (instanceObj.autoSave) {
          if (instanceObj.answerabilityOf === 'PAGE') {
            await instanceObj.pageModel.update(instanceObj.obj._id, instanceObj.obj);
          }
          instanceObj.list = await instanceObj.answerabilityModel.get({ nodeId: instanceObj.obj._id, nodeType: instanceObj.answerabilityOf });
          instanceObj.fillList();
        }
        instanceObj.onUpdate(instanceObj.obj);
      },
    });
  },

  getDayOfWeek(date) {
    const days = ['U', 'M', 'T', 'W', 'R', 'F', 'S'];
    const index = date.getDay();
    return days[index] || 'N/A';
  },

  convertStringTimeToNumber(time) {
    const hour = Number(time.split(':')[0]);
    const minute = Number(time.split(':')[1]) / 60;
    return hour + minute;
  },

  convertDateTimeToNumber(date) {
    const hour = date.getHours();
    const minute = date.getMinutes() / 60;
    return hour + minute;
  },

  convertSecondsToStringHHMM(seconds) {
    if (!seconds) {
      return '00:00';
    }
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    if (hours > 0) {
      return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    } else {
      return `00:${minutes < 10 ? `0${minutes}` : `${minutes}`}`;
    }
  },

  async link(instanceObj) {
    instanceObj.$dialog.picker({
      parent: instanceObj.parentType,
      subject: instanceObj.label,
      model: instanceObj.model,
      cloneable: !!instanceObj.cloneable,
      linkable: !!instanceObj.linkable,
      criteria: instanceObj.criteria,
      currentList: instanceObj.list,
      async callback(res) {
        if (res.isSuccess) {
          let additions = res.selected || [];

          if (!!instanceObj.cloneable && res.isClone) {
            additions = await Promise.all(
              res.selected.map(async (obj, index) => {
                const clone = await instanceObj.model.execute('clone', obj._id, { key: `${obj.key || 'NEW'}-C${index + 1}` });
                if (instanceObj.isOnProductionCourse) {
                  await instanceObj.model.update(clone._id, { needsPromotion: true });
                }
                return clone;
              }),
            );
          }
          await Promise.all(
            additions.map(async (obj) => {
              if (instanceObj.isOnProductionCourse) {
                await instanceObj.model.update(obj._id, { needsPromotion: true });
              }
              return obj;
            }),
          );

          if (instanceObj.answerabilityOf) {
            additions.forEach((addition) => {
              if (instanceObj.answerabilityOf === 'QUESTION') {
                instanceObj.list.push({ page: addition, pageId: addition._id });
              } else if (instanceObj.answerabilityOf === 'PAGE') {
                instanceObj.list.push({ question: addition, questionId: addition._id });
              }
            });
          } else {
            // Used for any live changes to linkList needed when it's updated (example: Coupon)
            instanceObj.list.push(...additions);
          }

          if (instanceObj.linkObj) {
            instanceObj.obj[instanceObj.childListKey].push(...additions.map((r) => ({ _id: r._id })));
          } else {
            instanceObj.obj[instanceObj.childListKey].push(...additions.map((r) => r._id));
          }

          instanceObj.onUpdate(instanceObj.obj);
          if (instanceObj.autoSave) {
            if (instanceObj.answerabilityOf === 'PAGE') {
              await instanceObj.pageModel.update(instanceObj.obj._id, instanceObj.obj);
            }
            instanceObj.list = await instanceObj.answerabilityModel.get({ nodeId: instanceObj.obj._id, nodeType: this.answerabilityOf });
            instanceObj.fillList();
          }
        }
        return true;
      },
    });
  },
  getCurrentEnvironment() {
    switch ((Auth.api || Session.get('api') || {}).app || '') {
      case 'ace-cxi-stg':
        return 'Staging';
      case 'ace-cxi-snd':
        return 'Sandbox';
      case 'ace-cxi-prd':
        return 'Production';
      case 'ace-cxi-gvt':
        return 'Government';
      default:
        return 'Local';
    }
  },
  capitalizeTitleCase(sentence) {
    return sentence
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  },

  /**
   * Downloads a file from a base64 string
   * Used with export concepts like in ClassEvent/edit.vue or Course/edit.vue
   */
  downloadBase64File({ fileName, mimeType, base64Content }) {
    const link = document.createElement('a');
    link.href = `data:${mimeType};base64,${base64Content}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};

export default Util;
